import { useQuery } from "react-query";
import { publicServerEndpoint } from "../services/helper";

const fetchCollections = async (storeId) => {
  const response = await fetch(`${publicServerEndpoint}/v2/product/store-collection/${storeId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch collections");
  }

  return response.json();
};

const useCollection = (storeId) => {
  return useQuery(["storeCollections", storeId], () => fetchCollections(storeId));
};
export default useCollection;
