import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import styles from "./featured-home.module.css";
import NavBar2 from "../NavBar2/NavBar2";
import Basket from "../Cart/Basket";
import StorageManager from "../../services/frontend/StorageManager";
import { CART_KEY } from "../../services/frontend/StorageKeys";
import { initialCart } from "../../services/ObjectsInitialValues";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useRouter } from "next/router";
import { publicServerEndpoint } from "../../services/helper";
import useStoreProducts from "../../hooks/useStoreProducts";
import FeaturedCollectionWithProd from "./FeaturedCollectionWithProd";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FeaturedHome = ({
  store,
  updateHomeActive,
  heroMedia,
  heroMediaImages,
  heroMediaType,
  heroTitle,
}) => {
  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [collections, setCollections] = useState([]); // Updated state
  const [showCart, setShowCart] = useState(false);
  const { productsData, collectionName } = useStoreProducts(store.id, null, 10); // Ensure this hook manages state correctly

  const handleShowCart = (val = false) => {
    setShowCart(val);
  };
  const router = useRouter();
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    //console.log('shubham:');
    //fetch the featured collection here. this collection will
    //display products on the home page below hero media
    const fetchCollections = async () => {
      try {
        let sortedCollections = [];
        if (store.collections && store.collections.length > 0) {
          sortedCollections = store.collections;
          //console.log("fetchedCollections " , fetchedCollections);
        } else {
          let url = `${publicServerEndpoint}/v2/product/store-collection/${store.id}/?limit=5`;
          const response = await fetch(url);
          const data = await response.json();

          //console.log("Fetched Collections for Store ID:", url, store.id, data);

          if (Array.isArray(data.data) && data.data.length > 0) {
            sortedCollections = data.data.sort((a, b) => a.localeCompare(b));
          }
        }

        const fetchedCollections = sortedCollections.map((collection) => ({
          collection,
          products: [], // Placeholder for products
        }));
        setCollections(fetchedCollections);

        //console.log("fetchedCollections:", fetchedCollections);

        // Fetch products for each collection
        fetchedCollections.forEach(async (collection, index) => {
          try {
            let productsUrl = `${publicServerEndpoint}/v2/product/store/${
              store.id
            }?page=${1}&limit=${10}&source=web&collection=${collection.collection}`;
            console.log("productsUrl", productsUrl);
            const productsResponse = await fetch(productsUrl);
            const productsData = await productsResponse.json();

            // console.log(
            //   `Fetched Products for Collection ID ${collection[0]}:`,
            //   productsUrl,
            //   productsData
            // );

            if (Array.isArray(productsData.data.docs) && productsData.data.docs.length > 0) {
              setCollections((prevCollections) => {
                const updatedCollections = [...prevCollections];
                updatedCollections[index].products = productsData.data.docs;
                return updatedCollections;
              });
            }
          } catch (error) {
            console.error(`Error fetching products for Collection ID ${collection.id}:`, error);
          }
        });
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };

    fetchCollections();
  }, [store.id, publicServerEndpoint]);

  return (
    <div>
      <div className={heroMediaType == "image" ? styles.heroForImages : styles.hero}>
        <Basket
          setRefresh={() => {}}
          isBasketOpen={showCart}
          setCart={setCart}
          cartData={cart}
          StorageManager={StorageManager}
          CART_KEY={CART_KEY}
          handleShowCart={handleShowCart}
          store={store}
        />
        <NavBar2
          cartActive={true}
          hideInAdvance={false}
          homeActive={true}
          storeName={heroTitle}
          handleShowCart={handleShowCart}
          store={store}
        />
        {heroMediaType == "image" ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            className={styles.carouselImageContainer}
            onClickItem={(index) => router.push("/shop")}
          >
            {heroMediaImages.map((imageUrl, index) => {
              const imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
              //console.log("imageUrl", imageName)
              const imgurlNew =
                store.cohort && store.cohort.includes(1)
                  ? `https://ik.imagekit.io/dm2asset/dm2assetfull/${imageName}`
                  : `https://dm2buy-aqbqh9cwb5cwb9he.z02.azurefd.net/dm2buy/${imageName}`;

              return (
                <div key={index}>
                  <img src={imgurlNew} alt={imageName} className={styles.carouselImage} />
                </div>
              );
            })}
          </Carousel>
        ) : (
          <ReactPlayer
            url={heroMedia}
            playing
            loop
            muted
            playsinline
            width="100%"
            height="100%"
            onClick={() => router.push("/shop")}
            className={styles.backgroundVideo}
          />
        )}
      </div>
      {true && (
        <div className={styles.homeDetailsContainer}>
          <div onClick={() => updateHomeActive(false)} className={styles.homeDetailsButton}>
            {"SHOP NOW"}
          </div>
        </div>
      )}
      {collections.length > 0 ? (
        collections.map((collection) => (
          <FeaturedCollectionWithProd
            cohort={store.cohort}
            key={collection.collection} // Ensure each component has a unique key
            collectionName={capitalizeFirstLetter(collection.collection)} // Assuming 'name' is a property
            productsData={collection.products}
          />
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default FeaturedHome;
