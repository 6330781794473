import styles from "./index.module.css";
import EllipsisText from "react-ellipsis-text";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import SideBar from "../SideBar";
import { useScrollDirection } from "react-use-scroll-direction";
import { mixpanel } from "../../services/helper";

const NavBar2 = (props) => {
  const [isHamOpen, setIsHamOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = React.useState(String);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  useEffect(() => {
    setLoading(true);
    let { store } = props;
  }, []);

  useEffect(() => {}, [products]);

  React.useEffect(() => {
    if (isHamOpen) return;
    isScrollingDown && setDirection("down");
    isScrollingUp && setDirection("up");
  }, [isScrollingDown, isScrollingUp]);

  const checkoutShouldHide = () => {
    let shouldHide = false;
    if (typeof window != "undefined") {
      if (window.scrollY > 120) {
        shouldHide = true;
      }
    }
    return shouldHide;
  };
  const hasBanner = props.store?.storeBanner?.text ?? false;
  return (
    <header
      className={`${styles.navBar} ${
        hasBanner ? styles.navBarWithBanner : styles.navBarWithoutBanner
      } ${direction === "up" ? styles.hideFade : ""} ${
        direction === "down" && checkoutShouldHide() ? styles.hide : ""
      }`}
      style={{
        backgroundColor: isHamOpen ? "transparent" : undefined,
      }}
    >
      <div className={styles.hamTitleContainer}>
        {/* <button className={styles.hamBtn} onClick={()=>setIsHamOpen((o)=>!o)}>
          {
            props.homeActive
            ? <div className={isHamOpen ? styles.open : null} >
              <span></span>
              <span></span>
              {
                isHamOpen 
                ? <>
                  <span></span>
                  <span></span>
                </> : ''
              }
            </div> : ''
          }
        </button> */}
        <h2
          className={styles.storeName}
          onClick={() => (window.location.href = window.location.origin)}
        >
          <EllipsisText
            // text={store.fields.store_name}
            text={props.storeName}
            length={25}
            tail="..."
            style={{ color: "white" }}
          />
        </h2>
        <span className={styles.cartIcon}>
          {props.cartActive && !props.hideInAdvance ? (
            <Image
              onClick={() => props.handleShowCart(true)}
              src="/cart-icon-white.svg"
              layout="fixed"
              width="21"
              height="21"
            />
          ) : (
            ""
          )}
        </span>
      </div>
      {/* <SideBar loading={loading} products={products} isHamOpen={isHamOpen} /> */}
    </header>
  );
};

export default NavBar2;
