import React from "react";
import styles from "./product-list.module.css";
import StoreItem from "../StoreItem";
import Link from "next/link";

const FeaturedCollectionWithProd = ({ cohort, collectionName, productsData }) => {
  return (
    <div className={styles.productList}>
      <h2 className={styles.collectionName}>{collectionName}</h2>
      <div className={styles.horizontalList}>
        {productsData.map((product) => (
          <StoreItem
            cohort={cohort}
            usedForCollectionhome={true}
            key={product.id}
            product={product}
            pageSize={productsData.length}
          />
        ))}
      </div>
      <Link href={`/shop?collection=${collectionName}`}>
        <a className={styles.viewAllButton}>View All</a>
      </Link>
    </div>
  );
};

export default FeaturedCollectionWithProd;
