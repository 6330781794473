import { useInfiniteQuery } from "react-query";
import { publicServerEndpoint } from "../services/helper";

const fetchProducts = async ({ queryKey, pageParam = 1 }) => {
  const [_, storeId, collection, pageSize] = queryKey;
  let url = `${publicServerEndpoint}/v3/product/store/${storeId}?page=${pageParam}&limit=${pageSize}&source=web`;
  if (collection && collection !== "all products") {
    url += `&collection=${collection}`;
  }

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }

  const data = await response.json();

  return {
    products: data.data.docs,
    nextPage: pageParam + 1,
    previousPage: pageParam > 1 ? pageParam - 1 : null,
    hasNextPage: data.data.hasNextPage,
  };
};

const useStoreProducts = (storeId, collection, pageSize) => {
  return useInfiniteQuery(["storeProducts", storeId, collection, pageSize], fetchProducts, {
    getNextPageParam: (lastPage) => (lastPage.hasNextPage ? lastPage.nextPage : undefined),
    keepPreviousData: true,
  });
};

export default useStoreProducts;
